<template lang="pug">
	Container
		main#register
			h2 Criar Usuário
			form(@submit.prevent="createUser")
				.input(:class="{'error': errors.name }")
					input(placeholder="Nome", type="text" v-model="form.name")
					span(v-if="errors.name") {{ errors.name[0] }}
				.input(:class="{'error': errors.email }")
					input(placeholder="E-mail", type="text" v-model="form.email")
					span(v-if="errors.email") {{ errors.email[0] }}
				.input(:class="{'error': errors.password || errors.message }")
					input(placeholder="Senha", type="password" v-model="form.password")
					span(v-if="errors.password") {{ errors.password[0] }}
				button(v-html="buttonText", :disabled="disabled", type="submit").btnX
				p(v-if="success") {{success}}
</template>

<script>
import Container from '@components/Container/Container.vue'
export default {
	name: "view-register",
	metaInfo() {
		return {
			title: 'register',
			meta: [
				{ name: 'description', content: '' },
			],
		}
	},
	data() {
		return {
			form: {
				name: '',
				email: '',
				password: '',
				type: 'admin'
			},
			success: null,
			errors: {},
			buttonText: "Enviar",
			disabled: false,
		}
	},
	components: {
		Container
	},
	methods: {
		createUser() {
			this.errors = { }
			this.buttonText = 'Enviando...'
			this.disabled = true
			this.$axios.post('users/', this.form)
				.then(response => (
					this.success = response.data
				)).catch(error => {
					this.errors = error.response.data
				}).finally(() => {
					setTimeout(() => {
						this.disabled = false
						this.buttonText = 'Enviar'
					}, 4000)
				})
		}
	}
}
</script>

<style lang="stylus" scoped src="./Register.styl"></style>
